<template>
  <WeContainer card="" v-if="ready">
    <WeCard class="sticky-top mb-3">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="m-0"><i class="far fa-copyright"></i> {{ getTitle }}</h5>
        </div>
        <div class="col-auto">
          <div class="row align-items-center">
            <div class="col-auto p-0">
              <WeLanguageSwitcher v-model="brandType.lang" />
            </div>
            <div class="col pl-0">
              <!-- Submit -->
              <WeSubmitButton
                parent-class="text-center"
                v-bind:submit-status="submit"
                v-bind:update="$route.params.id"
                v-bind:permission="checkPermission"
                v-on:submit="submitForm"
              />
              <!-- ./Submit -->
            </div>
          </div>
        </div>
      </div>
    </WeCard>

    <WeCard>
      <!-- Marka Adı -->
      <div class="form-group mb-3">
        <WeInput
          label="Marka Tipi Adı"
          name="brand_name"
          v-bind:required="true"
          v-model="form.name"
          v-bind:error="$v.form.name.$error"
        />
      </div>
      <!-- ./Marka Adı -->

      <WeSlugCheck
        v-bind:value="form.name"
        v-bind:id="$route.params.id"
        v-bind:slug="form.slug"
        v-bind:search="true"
        v-on:slugify="changeSlug"
        type="brand"
      />

      <WeDescription
        v-model="form.description"
        v-bind:access-token="session.accessToken"
        v-bind:name="'brand'"
        v-bind:compact="true"
        v-bind:title="$t('description')"
      />

      <div class="form-group">
        <label class="custom-label">Durum</label>
        <WeSwitch v-model="form.is_active" label="Aktif" />
      </div>
    </WeCard>
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Detail",
  data() {
    return {
      ready: false,
      submit: false,
      form: {
        name: null,
        is_active: true,
        slug: null,
        description: {
          text: null,
          show: false,
          place: "before",
        },
      },
      cleanData: {},
      cleanRequest: {},
      tmpLang: null,
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("brandType", ["create", "update", "getById"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    validateForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;

      if (this.$route.params.id) {
        if (this.tmpLang == this.brandType.lang) {
          this.cleanRequest = helper.difference(this.form, this.cleanData);
        } else this.cleanRequest = this.form;
        this.updateBrandType();
      } else {
        this.addBrandType();
      }
    },
    showConfirmation(cancelButtonText, id) {
      this.$swal({
        title: "İşlem Başarılı",
        text: "Marka Tipi listesine dönebilir veya bu sayfada kalabilirsiniz.",
        icon: "success",
        showCancelButton: true,
        cancelButtonText: cancelButtonText,
        confirmButtonText: "Liste Sayfasına Dön",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.redirectToList();
        } else {
          if (id) {
            this.$router.push(`detail/${id}`);
          } else {
            window.location.reload();
          }
        }
      });
    },
    addBrandType() {
      this.create({
        form: this.form,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Detaya Git", result.data.id);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateBrandType() {
      this.update({
        id: this.$route.params.id,
        form: this.cleanRequest,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Sayfada Kal");
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    getBrandTypeById() {
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.form = result;
            this.cleanData = helper.clone(this.form);
            this.ready = true;
          },
        });
      } else {
        this.ready = true;
      }
    },
    setChanged() {
      sessionStorage.setItem("changed", true);
    },
    removeChanged() {
      sessionStorage.removeItem("changed");
    },
    redirectToList() {
      this.$router.push("/brand-types");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    changeSlug(data) {
      this.form.slug = data;
    },
  },
  computed: {
    ...mapState(["brandType", "shared", "session"]),
    getTitle() {
      return this.$route.params.id ? "MARKA TİPİ DÜZENLE" : "MARKA TİPİ EKLE";
    },
    submitButtonText() {
      return this.$route.params.id ? "Güncelle" : "Kaydet";
    },
    checkPermission() {
      return permission.check("brand", "u");
    },
  },
  mounted() {
    this.getBrandTypeById();
    this.tmpLang = this.brandType.lang;
  },
  watch: {
    "brandType.lang": {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.ready = false;
          this.getBrandTypeById();
        }
      },
    },
  },
};
</script>
